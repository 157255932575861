














































import Vue from "vue";
/** TODO: This component is very similar than the all orders component
 * We can really merge these 2 components some time in the future
 */
import { namespace } from "vuex-class";
import * as qr from "qrcode-generator";
import { Shop } from "../store/shops/types";

export default Vue.extend({
  name: "ShopQRCode",

  data: function () {
    return {
      imgTag: "",
      activeShop: this.$store.state.shops.activeShop,
    };
  },

  mounted: function () {
    const qrCode = qr.default(0, "L");
    qrCode.addData(
      `${process.env.VUE_APP_QR_CODE}?code=${this.activeShop.code}`
    );
    qrCode.make();

    this.$data.imgTag = this.$vuetify.breakpoint.xsOnly
      ? qrCode.createImgTag(6)
      : qrCode.createImgTag(12);
  },

  methods: {
    async print() {
      await this.$nextTick();

      window.print();
    },
  },
});
